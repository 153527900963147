import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer, useGridApiContext, ptBR } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import { postListaClientesDashboard } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import { definicaoDeColunas, definicaoGrupoDeColunas } from './definicoes-grid';
import ModalGridDashboard from './ModalGridDashboard';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';
import { nivelUsuarioEnum } from '../../../../shared/Enums';
import ModalGridDashboardCheckpoints from '../ModalGridDashboardCheckpoints';
import ModalGridDashboardLimiteComercial from '../ModalGridDashboardLimiteComercial';
import ModalGridDashboardPipeComercial from '../ModalGridDashboardPipeComercial';
import ModalGridDashboardExibirProdutos from './ModalGridDashboardExibirProdutos';

const ClientesDashboard = ({ filtroDashboard, loadingDashFim, dadosUser, clienteAtual, setAlerta }) => {
  const qtdPorPagina = 10;
  const [listaClientes, setListaClientes] = useState([]);
  const [dadosAdicionaisGrid, setDadosAdicionaisGrid] = useState({});
  const [dadosListaCheckPoints, setListaCheckPoints] = useState([]);
  const [selectedCheckpoints, setSelectedCheckpoints] = useState([]);
  const [qtdMaxPaginas, setQtdMaxPaginas] = useState(false);
  const [esconderFooter, setEsconderFooter] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [modalGrid, setModalGrid] = useState(false);
  const [definicoesGrid, setDefinicoesGrid] = useState({
    colunas: [],
    grupoColunas: []
  });
  const [paginaSelecionada, setPaginaSelecionada] = useState(1);
  const [paginacaoModel, setPaginacaoModel] = useState({
    page: 0,
    pageSize: qtdPorPagina
  });
  const [expandirRetrairGrid, setExpandirRetrairGrid] = useState(true);
  const [tipoCliente, setTipoCliente] = useState('aprovados');
  const [modalGridCheckpoints, setModalGridCheckpoints] = useState(false);
  const [ModalGridExibirProdutos, setModalGridExibirProdutos] = useState(false);
  const [modalGridLimiteComercial, setModalGridLimiteComercial] = useState(false);
  const [modalGridPipeComercial, setModalGridPipeComercial] = useState(false);

  const buscarClientes = () => {
    let params = {
      filtro: {
        ...filtroDashboard,
        tipoCliente: tipoCliente.toUpperCase(),
        acoesCheckpoints: selectedCheckpoints
      }
    };
    setIsPending(true);
    postListaClientesDashboard(params)
      .then(res => {
        let lstDados = res && res.data && res.data.lista_clientes ? res.data.lista_clientes : [];
        setQtdMaxPaginas(Math.ceil(parseInt(lstDados.length || 0) / qtdPorPagina));
        setListaClientes(lstDados);
        setPaginaSelecionada(1);
        setPaginacaoModel({ page: 0, pageSize: qtdPorPagina });
        if (res && res.data && res.data.media_totais) {
          setDadosAdicionaisGrid({ dadosMedias: res.data.media_totais });
        }

        if (res && res.data && res.data.lista_checkPoint) {
          setListaCheckPoints(res.data.lista_checkPoint);
        }
      })
      .catch(() => {
        setListaClientes([]);
        setIsPending(false);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('ClientesDashboard');
      });
  };

  const carregarMaisDados = () => {
    let pageTemp = paginaSelecionada + 1;
    if (pageTemp <= qtdMaxPaginas) {
      setPaginaSelecionada(pageTemp);
    }
  };

  const clickGrupoEconomico = params => {
    setModalGrid({ modal: 'grupo_economico', modalTitle: 'Grupo Empresarial', params });
  };

  const clickStatus = params => {
    setModalGrid({ modal: 'status', modalTitle: 'Status', params });
  };

  const clickParecer = params => {
    setModalGrid({ modal: 'parecer', modalTitle: 'Pareceres', params });
  };

  const clickExibirProdutos = params => {
    setModalGridExibirProdutos({ params });
  };

  const clickCheckpoints = params => {
    setModalGridCheckpoints({
      cnpjCliente: params.cnpjCliente,
      nomeCliente: params.nomeCliente,
      modal: 'checkpoints',
      modalTitle: 'Checkpoints Negócios',
      ckeckpointsClientes: true,
      params
    });
  };

  const clickLimiteComercial = params => {
    setModalGridLimiteComercial({
      cnpjCliente: params.cnpjCliente,
      nomeCliente: params.nomeCliente,
      modal: 'limiteComercial',
      modalTitle: 'Limite Comercial',
      params
    });
  };

  const clickPipeComercial = params => {
    setModalGridPipeComercial({
      cnpjCliente: params.cnpjCliente,
      nomeCliente: params.nomeCliente,
      modal: 'limiteComercial',
      modalTitle: 'Limite Comercial',
      params
    });
  };

  function CustomExportButton() {
    const apiRef = useGridApiContext();

    const handleExport = () => {
      apiRef.current.exportDataAsCsv({
        fileName: 'Pipeline | Carteira | ' + moment().format('DDMMYYYY'),
        delimiter: ';',
        utf8WithBom: true
      });
    };

    return (
      <>
        <ButtonMain
          tipoBotao="transparente-com-bordas-principal"
          startIcon={<DownloadIcon />}
          className="exportarGridClientes"
          onClick={handleExport}
        >
          Exportar
        </ButtonMain>
      </>
    );
  }

  const expandeRetraiFunc = () => {
    setExpandirRetrairGrid(expandirRetrairGrid ? false : true);
  };

  const configuraColunasGrid = () => {
    if (filtroDashboard && filtroDashboard.filtrar === false) return false;
    let configColumnsTemp = definicaoDeColunas({
      clickCheckpoints,
      clickLimiteComercial,
      clickPipeComercial,
      clickGrupoEconomico,
      clickStatus,
      clickParecer,
      clickExibirProdutos,
      filtroDashboard,
      dadosUser,
      expandirRetrairGrid,
      expandeRetraiFunc,
      dadosAdicionaisGrid
    });

    let configGroupColumnsTemp = definicaoGrupoDeColunas({
      filtroDashboard,
      dadosUser,
      expandirRetrairGrid,
      dadosAdicionaisGrid
    });

    if (expandirRetrairGrid) {
      let novasColunas = [];
      let colunasProdutosRemover = [
        'limite_intercompany',
        'limite_giro_rapido',
        'limite_giro_parcelado',
        'limite_conta_escrow',
        'limite_escrow_simples',
        'risco_intercompany',
        'risco_giro_rapido',
        'risco_giro_parcelado',
        'risco_conta_escrow',
        'risco_escrow_simples',
        'disponivel_giro_rapido',
        'disponivel_intercompany',
        'disponivel_giro_parcelado',
        'disponivel_conta_escrow',
        'disponivel_escrow_simples'
      ];

      configColumnsTemp.map(function(coluna) {
        if (!colunasProdutosRemover.includes(coluna.field)) {
          novasColunas.push(coluna);
        }
        return coluna;
      });
      configColumnsTemp = novasColunas;
    }

    setDefinicoesGrid({ grupoColunas: configGroupColumnsTemp, colunas: configColumnsTemp });
  };

  useEffect(() => {
    configuraColunasGrid();
  }, [expandirRetrairGrid, dadosAdicionaisGrid]);

  useEffect(() => {
    if (paginaSelecionada === 1) return false;
    let pageSizeTemp = paginaSelecionada * qtdPorPagina;
    pageSizeTemp = pageSizeTemp < 100 ? pageSizeTemp : 100;
    setPaginacaoModel({ page: 0, pageSize: pageSizeTemp });
  }, [paginaSelecionada]);

  useEffect(() => {
    if (filtroDashboard && filtroDashboard.filtrar === false) return false;
    configuraColunasGrid();
    buscarClientes();
  }, [filtroDashboard, tipoCliente, selectedCheckpoints]);

  useEffect(() => {
    if (listaClientes.length > 100 && paginacaoModel.pageSize === 100) {
      setEsconderFooter(false);
    } else {
      setEsconderFooter(true);
    }
  }, [paginacaoModel, listaClientes]);

  const RadioClientesAprovadosComponente = () => {
    const handleTipoClienteChange = event => {
      setTipoCliente(event.target.value);
    };

    return (
      <Box className="float-left">
        <Typography variant="subtitle2" className="texto-input">
          Mostrar
        </Typography>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            row
            value={tipoCliente}
            onChange={handleTipoClienteChange}
            className="custom-radio-group"
          >
            <FormControlLabel value="aprovados" control={<Radio size="small" />} label="Aprovados" />
            <FormControlLabel value="bloqueados" control={<Radio size="small" />} label="Bloqueados" />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  function CustomNoRowsOverlay() {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="subtitle1">Nenhum registro encontrado</Typography>
      </Box>
    );
  }

  function CustomQuickFilter() {
    const apiRef = useGridApiContext();

    const handleFilterChange = event => {
      const value = event.target.value;
      apiRef.current.setFilterModel({
        items: [],
        quickFilterValues: value ? [value] : []
      });
    };
    return (
      <>
        <Typography variant="subtitle2" className="texto-input">
          Buscar por
        </Typography>
        <TextField
          size="small"
          placeholder="Digite palavras-chave..."
          fullWidth
          className="inputGridCliente"
          onChange={handleFilterChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </>
    );
  }

  return (
    <Box className="ajuste-spin-data-grid" sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {listaClientes !== false ? (
        <>
          <>
            <DataGrid
              className="data-grid-clientes-dashboard data-grid-column-group data-grid-separator-header-hidden"
              columns={definicoesGrid.colunas}
              columnGroupingModel={definicoesGrid.grupoColunas}
              experimentalFeatures={{ columnGrouping: true }}
              rows={listaClientes}
              paginationModel={paginacaoModel}
              onPaginationModelChange={setPaginacaoModel}
              pageSizeOptions={[100]}
              getRowHeight={() => 'auto'}
              autoHeight
              disableColumnMenu
              disableRowSelectionOnClick
              sx={{
                margin: '15px 0 30px 0',
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#EDEDF7'
                }
              }}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              hideFooter={esconderFooter}
              initialState={{}}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                toolbar: () => (
                  <GridToolbarContainer className="custom-toolbar-container">
                    <Grid container spacing={2} className="custom-toolbar-grid" alignItems="center">
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <CustomQuickFilter />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Typography variant="subtitle2" className="texto-input">
                          Buscar por ações de checkpoint
                        </Typography>
                        <FormControl size="small" fullWidth required>
                          <Select
                            multiple
                            displayEmpty
                            className="inputGridCliente"
                            value={selectedCheckpoints}
                            onChange={e => setSelectedCheckpoints(e.target.value)}
                            disabled={dadosListaCheckPoints.length === 0}
                            renderValue={selected => {
                              if (dadosListaCheckPoints.length === 0) {
                                return <>Nenhum checkpoint encontrado</>;
                              }
                              if (selected.length === 0) {
                                return <>Selecione uma opção</>;
                              }
                              const selectedDescriptions = selected.map(tag => {
                                const selecionado = dadosListaCheckPoints.find(item => item.tag === tag);
                                return selecionado ? selecionado.descricao : tag;
                              });
                              return (
                                <Box className="btnLimparConteudo">
                                  <span>{selectedDescriptions.join(', ')}</span>
                                  <IconButton
                                    onMouseDown={event => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                    }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      setSelectedCheckpoints([]);
                                    }}
                                    size="small"
                                  >
                                    <ClearIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              );
                            }}
                          >
                            {dadosListaCheckPoints.length > 0 ? (
                              <MenuItem value="">Selecione uma opção</MenuItem>
                            ) : (
                              <MenuItem value="">Nenhum checkpoint encontrado</MenuItem>
                            )}
                            {dadosListaCheckPoints.map(checkpoint => (
                              <MenuItem key={checkpoint.tag} value={checkpoint.tag}>
                                {checkpoint.descricao}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* Filtro "Mostrar" */}
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        {clienteAtual &&
                          clienteAtual.id === 'todos' &&
                          dadosUser &&
                          parseInt(dadosUser.nivel) !== nivelUsuarioEnum.CLIENTE && (
                            <RadioClientesAprovadosComponente />
                          )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1}
                        lg={1}
                        container
                        alignItems="right"
                        justifyContent="end"
                      >
                        <CustomExportButton />
                      </Grid>
                    </Grid>
                  </GridToolbarContainer>
                )
              }}
            />
            {esconderFooter && (
              <Grid container>
                <Grid item xl={5} lg={5} md={4} sm={2} xs={0} />
                <Grid item xl={2} lg={2} md={4} sm={5} xs={12}>
                  {paginaSelecionada < qtdMaxPaginas && (
                    <ButtonMain
                      type="button"
                      tipoBotao="transparente-com-bordas-principal"
                      onClick={() => carregarMaisDados()}
                      disabled={isPending}
                    >
                      <Box>
                        <Box className="display-inline-block">Carregar Mais</Box>
                        <Box className="icon-grid-clientes-qtd-paginas display-inline-block">
                          {String(
                            (qtdMaxPaginas < 10 ? qtdMaxPaginas : 10) - paginaSelecionada
                          ).padStart(2, '0')}
                        </Box>
                      </Box>
                    </ButtonMain>
                  )}
                </Grid>
                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={4}
                  sm={5}
                  xs={12}
                  className="flex-box items-centralizados-direita pad-right-left-5"
                >
                  <span className="font-size-1-rem texto-cor-cinza-escuro margin-right-7">
                    Clientes:
                  </span>
                  <span className="font-size-1-3-rem texto-cor-principal texto-weight-600">
                    {`${String(
                      paginacaoModel.pageSize < listaClientes.length
                        ? paginacaoModel.pageSize
                        : listaClientes.length
                    ).padStart(2, '0')}`}
                  </span>
                  <span className="font-size-1-rem texto-cor-principal margin-top-2">
                    {`/${String(listaClientes.length).padStart(2, '0')}`}
                  </span>
                </Grid>
              </Grid>
            )}
          </>
        </>
      ) : (
        <div className="error">
          <h1>Ops!</h1>
          <p>Alguma coisa deu errado, contate a equipe de suporte.</p>
        </div>
      )}
      <ModalGridDashboard modalGrid={modalGrid} setModalGrid={setModalGrid} />

      {ModalGridExibirProdutos && (
        <ModalGridDashboardExibirProdutos
          ModalGridExibirProdutos={ModalGridExibirProdutos}
          setModalGridExibirProdutos={setModalGridExibirProdutos}
          listaClientes={listaClientes}
          setListaClientes={setListaClientes}
          dadosUser={dadosUser}
        />
      )}

      {modalGridCheckpoints && (
        <ModalGridDashboardCheckpoints
          modalGridCheckpoints={modalGridCheckpoints}
          setModalGridCheckpoints={setModalGridCheckpoints}
          listaClientes={listaClientes}
          setListaClientes={setListaClientes}
        />
      )}
      {modalGridLimiteComercial && (
        <ModalGridDashboardLimiteComercial
          modalGridLimiteComercial={modalGridLimiteComercial}
          setModalGridLimiteComercial={setModalGridLimiteComercial}
          setListaClientes={setListaClientes}
          clienteAtual={clienteAtual}
          setAlerta={setAlerta}
        />
      )}
      {modalGridPipeComercial && (
        <ModalGridDashboardPipeComercial
          modalGridPipeComercial={modalGridPipeComercial}
          setModalGridPipeComercial={setModalGridPipeComercial}
          listaClientes={listaClientes}
          setListaClientes={setListaClientes}
          setAlerta={setAlerta}
        />
      )}
    </Box>
  );
};

export default ClientesDashboard;
