import React, { useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer
} from '@mui/material';
import { Close, EventBusy, EventRepeat, Lock } from '@mui/icons-material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { exibeValorLocalizado, exibeValorResumido } from '../../../../shared';
import CorrenteFechada from '../../../../assets/img/corrente_fechada.svg';
import { nivelUsuarioEnum } from '../../../../shared/Enums';

const ModalGridDashboardExibirProdutos = ({
  ModalGridExibirProdutos,
  setModalGridExibirProdutos,
  dadosUser
}) => {
  const params = (ModalGridExibirProdutos && ModalGridExibirProdutos.params) || {};

  const imageStyle = { width: 12, height: 12, marginLeft: 5, marginTop: -5 };

  const formatarListaProdutos = produtos => {
    if (!produtos || produtos.length === 0) return '';
    if (produtos.length === 1) return produtos[0];
    return produtos.slice(0, -1).join(', ') + ' e ' + produtos[produtos.length - 1];
  };

  const limiteRiscoDisponivel = (valorSemFormatacao, params, siglaProd, nomeColuna) => {
    const [coluna, tipoProd = ''] = nomeColuna.split('_');
    let valor =
      valorSemFormatacao && parseInt(valorSemFormatacao, 10) !== 0
        ? parseInt(valorSemFormatacao, 10)
        : 0;
    const produtosConjunto = params.produtosConjunto || [];

    let negativa = false;
    if (valor < 0) {
      valor = Math.abs(valor);
      negativa = true;
    }
    const statusLim = coluna !== 'risco' ? params[`status_${tipoProd}`] || 'ok' : 'ok';

    if (valor === 0) {
      return (
        <Box>
          <HorizontalRuleIcon className="texto-cor-cinza-claro" />
        </Box>
      );
    }

    return (
      <Box className="texto-nowrap-overflow-dotted">
        <span
          title={`R$ ${negativa ? '-' : ''}${exibeValorLocalizado(valor)}`}
          className={
            negativa || (coluna !== 'risco' && (statusLim === 'bloqueado' || statusLim === 'vencido'))
              ? 'texto-danger'
              : ''
          }
        >
          {`${negativa ? '-' : ''}${exibeValorResumido(valor)}`}
        </span>
        {siglaProd && produtosConjunto.includes(siglaProd) && (
          <img
            src={CorrenteFechada}
            style={imageStyle}
            title="Valor do conjunto"
            alt="Valor do conjunto"
          />
        )}
        {statusLim === 'bloqueado' &&
          coluna !== 'risco' &&
          dadosUser &&
          dadosUser.nivel !== nivelUsuarioEnum.CLIENTE && (
            <span title="Limite Bloqueado">
              <Lock className="texto-danger ajustar-icone-direita" />
            </span>
          )}
        {statusLim === 'vencido' && coluna !== 'risco' && (
          <span title="Limite Vencido">
            <EventBusy className="texto-danger ajustar-icone-direita" />
          </span>
        )}
        {statusLim === 'vai_vencer' && coluna !== 'risco' && (
          <span title="Limite próximo do vencimento">
            <EventRepeat className="texto-warning ajustar-icone-direita" />
          </span>
        )}
      </Box>
    );
  };

  const { productArray, limitesVencidos, produtosConjunto } = useMemo(() => {
    const info = params.infoProdutos;
    if (!info) return { productArray: [], limitesVencidos: [], produtosConjunto: [] };

    const productArray = Object.keys(info).map(key => ({
      id: key,
      ...info[key]
    }));
    const limitesVencidos = params.limitesVencidos || [];
    const produtosConjunto = params.produtosConjunto || [];
    return { productArray, limitesVencidos, produtosConjunto };
  }, [params]);

  if (!ModalGridExibirProdutos) return null;

  return (
    <Dialog
      open={!!ModalGridExibirProdutos}
      fullWidth
      maxWidth="sm"
      keepMounted
      onClose={() => setModalGridExibirProdutos(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '2px solid #ccc',
            pb: 1
          }}
        >
          <Typography variant="h6" component="div" className="azul-one7-cor">
            Produtos Ativos
          </Typography>
          <IconButton onClick={() => setModalGridExibirProdutos(false)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableContainer className="tableExibirProdutos texto-cor-cinza-escuro">
          <Table>
            <TableHead className="azul-claro-background">
              <TableRow>
                <TableCell className="font-bold produto-col">Produto</TableCell>
                <TableCell className="font-bold" align="right">
                  Limite
                </TableCell>
                <TableCell className="font-bold" align="right">
                  Risco
                </TableCell>
                <TableCell className="font-bold" align="right">
                  Disponível
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productArray.map(produto => (
                <TableRow key={produto.id}>
                  <TableCell className="produto-col">{produto.nomeProduto}</TableCell>
                  <TableCell align="right">
                    {limiteRiscoDisponivel(
                      produto.limite,
                      params,
                      '',
                      `limite_${produto.tagProduto || ''}`
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {limiteRiscoDisponivel(
                      produto.risco,
                      params,
                      '',
                      `risco_${produto.tagProduto || ''}`
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {limiteRiscoDisponivel(
                      produto.disponivel,
                      params,
                      '',
                      `disponivel_${produto.tagProduto || ''}`
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {limitesVencidos.length > 0 && (
          <Box sx={{ mt: 3 }} className="azul-claro-background alertasProduto texto-cor-cinza-escuro">
            {produtosConjunto.length > 0 && (
              <Typography variant="body2" className="margin-topbot-10">
                <span className="font-bold">Limites conjunto </span>
                <span>para os produtos </span>
                {formatarListaProdutos(produtosConjunto.map(produto => produto.NOME))}
              </Typography>
            )}
            {limitesVencidos.map(limite => (
              <Typography key={limite.id} className="margin-topbot-10" variant="body2">
                <span className="font-bold">{`Limite ${limite.nomeProduto}`}</span>
                {limite.tipoVencimento === 'vencido' ? ' venceu ' : ' irá vencer '}
                {`em ${limite.vencimento}`}
              </Typography>
            ))}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardExibirProdutos;
