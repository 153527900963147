const indicesNomeProdutos = {
  1: 'normal',
  2: 'comissaria',
  3: 'giro_rapido',
  4: 'intercompany',
  5: 'giro_parcelado',
  6: 'fianca_bancaria',
  7: 'trustee',
  8: 'ccb_renegociacao',
  9: 'ccb_consignado',
  10: 'cessao_credito',
  11: 'renegociacao',
  12: 'giro_parcelado_xpone7',
  16: 'antecipacao_bndes',
  18: 'conta_escrow',
  19: 'escrow_simples'
};

export default indicesNomeProdutos;
