import { Avatar, Box, Grid, IconButton, Typography, Menu, MenuItem, Button } from '@mui/material';
import React, { useState } from 'react';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { WarningAmberRounded, Lock, EventBusy, EventRepeat } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { exibeValorLocalizado, exibeValorResumido, mascararCpfCnpj } from '../../../../shared';
import CorrenteAberta from '../../../../assets/img/corrente_aberta.svg';
import CorrenteFechada from '../../../../assets/img/corrente_fechada.svg';
import CorrenteFechadaBranca from '../../../../assets/img/corrente_fechada_branca.svg';
import { nivelUsuarioEnum } from '../../../../shared/Enums';

const formatarValorMonetario = params => {
  var valor = params.value && parseInt(params.value) !== 0 ? parseInt(params.value) : 0;
  let negativa = false;
  if (valor < 0) {
    valor = parseFloat(String(valor).replace('-', ''));
    negativa = true;
  }
  return (
    <>
      {valor !== 0 ? (
        <Box className="texto-nowrap-overflow-dotted">
          <span
            title={'R$ ' + (negativa ? '-' : '') + exibeValorLocalizado(valor)}
            className={negativa ? 'texto-danger' : ''}
          >
            {(negativa ? '-' : '') + exibeValorResumido(valor)}
          </span>
        </Box>
      ) : (
        <Box>
          <HorizontalRuleIcon className="texto-cor-cinza-claro" />
        </Box>
      )}
    </>
  );
};

const limiteRiscoDisponivel = (params, siglaProd, nomeColuna, dadosUser) => {
  let tipoProdColuna = nomeColuna.split('_');
  let tipoProd = tipoProdColuna[1];
  var coluna = tipoProdColuna[0];
  var produtosConjunto = params.row && params.row.produtosConjunto ? params.row.produtosConjunto : [];
  const existeConjunto = produtosConjunto.some(produto => produto.SIGLA === siglaProd);
  var valor = params.value && parseInt(params.value) !== 0 ? parseInt(params.value) : 0;
  let negativa = false;
  if (valor < 0) {
    valor = parseFloat(String(valor).replace('-', ''));
    negativa = true;
  }
  var statusLim =
    coluna !== 'risco' && params.row && params.row['status_' + tipoProd]
      ? params.row['status_' + tipoProd]
      : 'ok';
  return (
    <>
      {valor !== 0 ? (
        <Box className="texto-nowrap-overflow-dotted">
          <span
            title={'R$ ' + (negativa ? '-' : '') + exibeValorLocalizado(valor)}
            className={
              negativa || (coluna !== 'risco' && (statusLim === 'bloqueado' || statusLim === 'vencido'))
                ? 'texto-danger'
                : ''
            }
          >
            {(negativa ? '-' : '') + exibeValorResumido(valor)}
          </span>
          {siglaProd && existeConjunto && (
            <img
              src={CorrenteFechada}
              style={{ width: 12, height: 12, marginLeft: 5, marginTop: -5 }}
              title="Valor do conjunto"
              alt="Valor do conjunto"
            />
          )}
          {statusLim === 'bloqueado' &&
            coluna !== 'risco' &&
            dadosUser &&
            dadosUser.nivel !== nivelUsuarioEnum.CLIENTE && (
              <span title="Limite Bloqueado">
                <Lock className="texto-danger ajustar-icone-direita" />
              </span>
            )}
          {statusLim === 'vencido' && coluna !== 'risco' && (
            <span title="Limite Vencido">
              <EventBusy className="texto-danger ajustar-icone-direita" />
            </span>
          )}
          {statusLim === 'vai_vencer' && coluna !== 'risco' && (
            <span title="Limite próximo do vencimento">
              <EventRepeat className="texto-warning ajustar-icone-direita" />
            </span>
          )}
        </Box>
      ) : (
        <Box>
          <HorizontalRuleIcon className="texto-cor-cinza-claro" />
        </Box>
      )}
    </>
  );
};

const obterTituloMedia = (dados, tipo, produto) => {
  var titulo = '';
  if (dados && dados.dadosMedias && dados.dadosMedias[tipo]) {
    if (
      produto !== 'total' &&
      dados.dadosMedias[tipo].mediaProdutos &&
      dados.dadosMedias[tipo].mediaProdutos[produto]
    ) {
      titulo = 'A media é de R$ ' + exibeValorLocalizado(dados.dadosMedias[tipo].mediaProdutos[produto]);
    } else if (produto === 'total' && dados.dadosMedias[tipo].mediaTotal) {
      titulo = 'A media total é de R$ ' + exibeValorLocalizado(dados.dadosMedias[tipo].mediaTotal);
    }

    if (produto !== 'total' && dados.dadosMedias[tipo].qtd && dados.dadosMedias[tipo].qtd[produto]) {
      titulo += ' referente a ' + dados.dadosMedias[tipo].qtd[produto] + ' clientes.';
    } else if (produto === 'total' && dados.dadosMedias[tipo].qtdTotal) {
      titulo += ' referente a ' + dados.dadosMedias[tipo].qtdTotal + ' clientes.';
    }
  }
  return titulo;
};

const ActionsCell = ({
  params,
  clickCheckpoints,
  clickParecer,
  clickLimiteComercial,
  clickPipeComercial,
  clickStatus
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckpointClick = () => {
    clickCheckpoints(params.row);
    handleClose();
  };

  const handleParecerClick = () => {
    clickParecer(params.row);
    handleClose();
  };

  const handleLimiteComercialClick = () => {
    clickLimiteComercial(params.row);
    handleClose();
  };

  const handlePipeComercialClick = () => {
    clickPipeComercial(params.row);
    handleClose();
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <IconButton onClick={handleClick} sx={{ color: '#1976d2' }}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleCheckpointClick}>Checkpoint de negócios</MenuItem>
        <MenuItem onClick={handleParecerClick}>Parecer</MenuItem>
        <MenuItem onClick={handleLimiteComercialClick}>Limite comercial</MenuItem>
        <MenuItem onClick={handlePipeComercialClick}>Pipe comercial</MenuItem>
      </Menu>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center'
        }}
      >
        {params.row.status && params.value && ['danger', 'warning'].includes(params.value) && (
          <IconButton onClick={() => clickStatus(params.row)} sx={{ p: '0 2px' }}>
            <WarningAmberRounded className={`texto-${params.value}`} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export const definicaoDeColunas = ({
  clickCheckpoints,
  clickLimiteComercial,
  clickPipeComercial,
  clickGrupoEconomico,
  clickStatus,
  clickParecer,
  filtroDashboard,
  clickExibirProdutos,
  dadosUser,
  expandirRetrairGrid,
  dadosAdicionaisGrid
}) => {
  var configColumns = [
    {
      field: 'nomeCliente',
      headerName: 'Empresa',
      headerAlign: 'left',
      minWidth: 100,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      sortingOrder: ['asc', 'desc'],
      renderCell: params => criarClienteNomeCnpj({ params, clickGrupoEconomico })
    },
    {
      field: 'porteCliente',
      headerName: 'Porte',
      headerAlign: 'left',
      maxWidth: 90,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'font-size-0-8-rem data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => v1 - v2,
      sortingOrder: ['asc', 'desc'],
      renderHeader: () => (
        <Box title="Porte do Cliente" className="texto-nowrap-overflow-dotted">
          Porte
        </Box>
      ),
      renderCell: params => {
        return (
          <>
            {params.value !== null ? (
              <Box>
                <Box>{`EMP ${params.value !== null ? params.value : ''}`}</Box>
              </Box>
            ) : (
              <Box>
                <HorizontalRuleIcon className="texto-cor-cinza-claro" />
              </Box>
            )}
          </>
        );
      }
    },
    {
      field: 'qtdProd',
      headerName: 'Qtd. Prod.',
      headerAlign: 'left',
      maxWidth: 90,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'font-size-0-8-rem data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => v1 - v2,
      sortingOrder: ['asc', 'desc'],
      renderHeader: () => (
        <Box title="Porte do Cliente" className="texto-nowrap-overflow-dotted">
          Qtd. Prod.
        </Box>
      ),
      renderCell: params => {
        return (
          <Button
            className="btn-qtd-produtos"
            onClick={params.value > 0 ? () => clickExibirProdutos(params.row) : undefined}
            disabled={!params.value || params.value <= 0}
          >
            {params.value}
          </Button>
        );
      }
    },
    {
      field: 'limite_normal',
      headerName: 'NO',
      headerAlign: 'left',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'data-grid-light-blue items-final-direita',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'NO', 'limite_normal', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_comissaria',
      headerName: 'CM',
      headerAlign: 'left',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: `data-grid-light-blue font-bold-header ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      cellClassName: `data-grid-light-blue items-final-direita ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'CM', 'limite_comissaria', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_normal',
      headerName: 'NO',
      headerAlign: 'left',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'data-grid-light-blue items-final-direita',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'normal'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'NO', 'risco_normal', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_comissaria',
      headerName: 'CM',
      headerAlign: 'left',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: `data-grid-light-blue font-bold-header ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      cellClassName: `data-grid-light-blue items-final-direita ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'comissaria'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'CM', 'risco_comissaria', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_normal',
      headerName: 'NO',
      headerAlign: 'left',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'data-grid-light-blue items-final-direita',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'NO', 'disponivel_normal', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_comissaria',
      headerName: 'CM',
      headerAlign: 'left',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: `data-grid-light-blue font-bold-header ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      cellClassName: `data-grid-light-blue items-final-direita ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'CM', 'disponivel_comissaria', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'valorPipeComercial',
      headerName: 'Pipe com.',
      headerAlign: 'left',
      renderHeader: () => (
        <Box title="Última Operação (dias)" className="texto-nowrap-overflow-dotted">
          Pipe com.
        </Box>
      ),
      minWidth: 65,
      maxWidth: 95,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold border-2-right-white',
      sortComparator: (v1, v2) => {
        v1 = v1 !== '-' && v1 !== null ? parseInt(v1) : 0;
        v2 = v2 !== '-' && v2 !== null ? parseInt(v2) : 0;
        return v1 - v2;
      },
      sortingOrder: ['desc', 'asc'],
      cellClassName: `data-grid-light-blue items-final-direita ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      renderCell: params => (
        <Box onClick={() => clickPipeComercial(params.row)} style={{ cursor: 'pointer' }}>
          {formatarValorMonetario(params)}
        </Box>
      )
    },
    {
      field: 'valorVop',
      headerName: 'VOP',
      headerAlign: 'left',
      renderHeader: () => (
        <Box title="Última Operação (dias)" className="texto-nowrap-overflow-dotted">
          VOP
        </Box>
      ),
      minWidth: 65,
      maxWidth: 95,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold border-2-right-white',
      sortComparator: (v1, v2) => {
        v1 = v1 !== '-' && v1 !== null ? parseInt(v1) : 0;
        v2 = v2 !== '-' && v2 !== null ? parseInt(v2) : 0;
        return v1 - v2;
      },
      sortingOrder: ['desc', 'asc'],
      cellClassName: `data-grid-light-blue items-final-direita ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      renderCell: params => formatarValorMonetario(params)
    },
    {
      field: 'dias_ultima_operacao',
      headerName: 'Churn',
      headerAlign: 'left',
      renderHeader: () => (
        <Box title="Última Operação (dias)" className="texto-nowrap-overflow-dotted">
          Churn
        </Box>
      ),
      minWidth: 65,
      maxWidth: 95,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold border-2-right-white',
      sortComparator: (v1, v2) => {
        v1 = v1 !== '-' && v1 !== null ? parseInt(v1) : 0;
        v2 = v2 !== '-' && v2 !== null ? parseInt(v2) : 0;
        return v1 - v2;
      },
      sortingOrder: ['desc', 'asc'],
      cellClassName: params => {
        let classes = 'data-grid-light-blue border-2-right-white ';
        if (params.value === '-') {
          classes += 'texto-cor-cinza-claro';
        } else {
          let dias = parseInt(params.value);
          if (dias <= 30) classes += 'texto-success';
          else if (dias > 30 && dias <= 120) classes += 'texto-warning';
          else if (dias > 120) classes += 'texto-danger';
        }
        return classes;
      },
      renderCell: params => {
        return (
          <Box>
            <Box title={params.row.data_ultima_operacao}>
              {params.value ? params.value + ' Dias' : ''}
            </Box>
          </Box>
        );
      }
    }
  ];

  if (filtroDashboard && filtroDashboard.gerente && filtroDashboard.gerente === 'todos') {
    configColumns.unshift({
      field: 'nomeGerente',
      headerName: 'GN',
      headerAlign: 'left',
      minWidth: 50,
      maxWidth: 66,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      sortingOrder: ['asc', 'desc'],
      renderCell: params => criarGerenteAvatar(params)
    });
  }

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configColumns.unshift({
      field: 'corSiga',
      headerName: '',
      flex: 1,
      minWidth: 15,
      maxWidth: 15,
      headerClassName: 'borda-cor-siga-head data-grid-light-blue border-2-right-white',
      cellClassName: 'borda-cor-siga',
      renderCell: params => (
        <Box
          title={`${params.row && params.row.corSiga ? params.row.corSiga.split('|')[0] : ''}`}
          className="texto-nowrap-overflow-dotted"
          sx={{
            borderLeft: `8px solid ${
              params.row && params.row.corSiga ? params.row.corSiga.split('|')[1] : ''
            }`,
            minHeight: '60px',
            width: '5px'
          }}
        />
      )
    });
  }

  if (dadosUser && dadosUser.nivel === nivelUsuarioEnum.CLIENTE) {
    configColumns = configColumns.filter(function(el) {
      return el.field !== 'porteCliente';
    });
  }

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configColumns.push({
      field: 'tipoStatus',
      headerName: 'Ações',
      minWidth: 80,
      maxWidth: 140,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName:
        'flex-box-items-centralizados items-centralizados data-grid-light-blue border-2-right-white',
      sortable: false,
      renderCell: params => (
        <>
          <ActionsCell
            params={params}
            clickCheckpoints={clickCheckpoints}
            clickParecer={clickParecer}
            clickLimiteComercial={clickLimiteComercial}
            clickPipeComercial={clickPipeComercial}
            dadosUser={dadosUser}
            clickStatus={clickStatus}
          />
        </>
      )
    });
  }

  return configColumns;
};

export const criarGerenteAvatar = params => {
  var tituloGerente = params.value;
  if (params.row && params.row.nomePlataforma)
    tituloGerente += '\nPlataf.: ' + params.row.nomePlataforma;
  if (params.row && params.row.nomeUnidadeNegocio)
    tituloGerente += '\nUnd.Neg.: ' + params.row.nomeUnidadeNegocio;
  return (
    <>
      {params.value && params.value === '-' ? (
        <HorizontalRuleIcon className="texto-cor-cinza-claro" />
      ) : (
        <Grid container title={tituloGerente}>
          <Grid item xs={12} className="flex-box-items-centralizados pad-top-5">
            <Typography
              variant="subtitle1"
              className="texto-cor-preto font-size-0-7rem texto-hidden-overflow"
            >
              {params.value}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const criarClienteNomeCnpj = ({ params, clickGrupoEconomico }) => {
  if (params.value === 'Total geral') {
    return (
      <Typography component="div" className="font-bold pad-topbot-20">
        Total geral
      </Typography>
    );
  }

  return (
    <Box
      className="texto-nowrap-overflow-dotted"
      sx={{
        display: 'flex',
        alignItems: 'center',
        maxWidth: 220,
        overflow: 'hidden'
      }}
    >
      <IconButton
        onClick={() => clickGrupoEconomico(params.row)}
        id="btn-grupo-economico"
        className="icon-button-contained"
        disabled={!params.row.grupoEconomico}
        size="small"
      >
        {params.row.grupoEconomico ? (
          <>
            <Avatar id="chain" src={CorrenteFechada} sx={{ width: 18, height: 18 }} />
            <Avatar id="chain_white" src={CorrenteFechadaBranca} sx={{ width: 18, height: 18 }} />
          </>
        ) : (
          <Avatar src={CorrenteAberta} sx={{ width: 22, height: 22 }} />
        )}
      </IconButton>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ml: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          minWidth: 0
        }}
      >
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          className="texto-nowrap-overflow-dotted"
          title={params.value}
        >
          {params.value}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          className="texto-nowrap-overflow-dotted"
          title={params.row.cnpjCliente}
        >
          {mascararCpfCnpj(params.row.cnpjCliente)}
        </Typography>
      </Box>
    </Box>
  );
};

export const criarGerenteAvatarPipeLine = params => {
  var tituloGerente = params.value;
  if (params.row && params.row.nomePlataforma)
    tituloGerente += '\nPlataf.: ' + params.row.nomePlataforma;
  if (params.row && params.row.nomeUnidadeNegocio)
    tituloGerente += '\nUnd.Neg.: ' + params.row.nomeUnidadeNegocio;
  return (
    <>
      {params.value && params.value === '-' ? (
        <HorizontalRuleIcon className="texto-cor-cinza-claro" />
      ) : (
        <Grid container title={tituloGerente}>
          <Grid item xs={12} className="flex-box-items-centralizados pad-top-5">
            <Typography
              variant="subtitle1"
              className="texto-cor-preto font-size-0-7rem texto-hidden-overflow"
            >
              {params.value}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const criarClienteNomeCnpjPipeLine = params => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="texto-left"
        title={params.value !== 'Total geral' ? params.value : ''}
      >
        <Typography
          className={
            params.value !== 'Total geral' ? 'texto-nowrap-overflow-dotted' : 'font-bold pad-topbot-20'
          }
        >
          {params.value}
        </Typography>
      </Grid>
      {params.row.cnpjCliente && (
        <Grid item xs={12} title={mascararCpfCnpj(params.row.cnpjCliente)}>
          <Typography
            variant="subtitle1"
            className="texto-nowrap-overflow-dotted font-size-0-8rem texto-cinza-medio"
          >
            {mascararCpfCnpj(params.row.cnpjCliente)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const definicaoGrupoDeColunas = ({
  filtroDashboard,
  dadosUser,
  expandirRetrairGrid,
  dadosAdicionaisGrid
}) => {
  let arrayLimites = [{ field: 'limite_normal' }, { field: 'limite_comissaria' }];

  let arrayRisco = [{ field: 'risco_normal' }, { field: 'risco_comissaria' }];

  let arrayDisponivel = [{ field: 'disponivel_normal' }, { field: 'disponivel_comissaria' }];

  if (expandirRetrairGrid) {
    arrayLimites = [{ field: 'limite_normal' }, { field: 'limite_comissaria' }];
    arrayRisco = [{ field: 'risco_normal' }, { field: 'risco_comissaria' }];
    arrayDisponivel = [{ field: 'disponivel_normal' }, { field: 'disponivel_comissaria' }];
  }

  var configGroupColumns = [
    {
      groupId: 'grp_grupoEconomico',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'grupoEconomico' }]
    },
    {
      groupId: 'grp_nomeCliente',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'nomeCliente' }]
    },
    {
      groupId: 'porteCliente',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'porteCliente' }]
    },
    {
      groupId: 'qtdProd',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'qtdProd' }]
    },
    {
      groupId: 'Limite',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      children: arrayLimites
    },
    {
      groupId: 'Risco',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'total'),
      children: arrayRisco
    },
    {
      groupId: 'Limite disponível',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      children: arrayDisponivel
    },
    {
      groupId: 'grp_dias_ultima_operacao',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'dias_ultima_operacao' }]
    }
  ];

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configGroupColumns.unshift({
      groupId: 'grp_grupoCheckpoint',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'tipoStatus' }]
    });
  }

  if (filtroDashboard && filtroDashboard.gerente && filtroDashboard.gerente === 'todos') {
    configGroupColumns.unshift({
      groupId: 'grp_nomeGerente',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'nomeGerente' }]
    });
  }

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configGroupColumns.unshift({
      groupId: 'cor_siga',
      headerClassName: 'header-cor-siga data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'corSiga' }]
    });
  }

  if (dadosUser && dadosUser.nivel === nivelUsuarioEnum.CLIENTE) {
    configGroupColumns = configGroupColumns.filter(function(el) {
      return el.groupId !== 'porteCliente';
    });
  }

  return configGroupColumns;
};
