import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import { Close, Feed, Search } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import {
  criarGerenteAvatarPipeLine,
  criarClienteNomeCnpjPipeLine
} from '../ClientesDashboard/definicoes-grid';
import ModalGridDashboardPareceres from './ModalGridDashboardPareceres';
import EsteiraProposta from './EsteiraProposta';
import { postListarPropostasEsteira } from '../../../../api/dashboard';
import { mascararCpfCnpj, useEffectAfterMount } from '../../../../shared';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';
import { etapasPropostas, nivelUsuarioEnum } from '../../../../shared/Enums';
import AlertErroMain from '../../../Onboarding/shared/AlertErroMain';
import ModalEsteiraProposta from './ModalEsteiraProposta';
import { likeStringMatch } from '../../../../utils/format';
import ModalGridDashboardCheckpoints from '../ModalGridDashboardCheckpoints';
import { retornaInformacoesNasPillsDeCheckpoints } from '../../../Onboarding/shared/Utils';

const PropostasEsteira = ({
  filtroDashboard,
  dadosUser,
  parentAbaAtiva,
  parentMudarAbaAtiva,
  setLoadPipelinePropostas
}) => {
  const lstEtapas = Object.values(etapasPropostas);
  var etapasTotaisIniciais = {};
  lstEtapas.map(etp => {
    etapasTotaisIniciais[etp.etapa] = 0;
    return etp;
  });

  const [abaAtiva, mudarAba] = useState(0);
  const [filtrarEtapa, setFiltrarEtapa] = useState(false);
  const [buscarHistoricoAtivo, setBuscarHistoricoAtivo] = useState(false);
  const [showHistoricoAtivo, setShowHistoricoAtivo] = useState(false);
  const [lstPropostasEsteira, setLstPropostasEsteira] = useState([]);
  const [lstPropostasEsteiraEtapa, setLstPropostasEsteiraEtapa] = useState([]);
  const [lstPropostasEsteiraCompleta, setLstPropostasEsteiraCompleta] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [mostrarGerente, setMostrarGerente] = useState(true);
  const [qtdMostrar, setQtdMostrar] = useState(10);
  const [etapasTotais, setEtapasTotais] = useState(etapasTotaisIniciais);
  const [propostaCliente, setPropostaCliente] = useState(false);
  const [mostraErro, setMostraErro] = useState(false);
  const [modalDados, setModalDados] = useState(false);
  const [valorFiltro, setValorFiltro] = useState(false);
  const [buscarPropostas, setBuscarPropostas] = useState(false);
  const location = useLocation();
  const [modalGrid, setModalGrid] = useState(false);
  const [modalGridCheckpoints, setModalGridCheckpoints] = useState(false);

  const lstTiposPropostas = {
    cadastro_novo: 'Cadastro Novo',
    renovacao: 'Renovação',
    reativacao: 'Reativação',
    one7_mais_credito: 'One7MaisCrédito'
  };

  const buscarPropostasEsteiras = () => {
    let filtro = filtroDashboard ? filtroDashboard : {};
    let etapaEsteira = filtrarEtapa !== false ? filtrarEtapa : 'TODAS';
    let params = {
      etapaEsteira: etapaEsteira,
      ativos_historico: buscarHistoricoAtivo,
      ...filtro
    };

    setMostraErro(false);
    setIsPending(true);
    setLoadPipelinePropostas(true);
    postListarPropostasEsteira(params)
      .then(res => {
        let lstDados = res && res.data ? res.data : [];
        setLstPropostasEsteira(lstDados);
        setLstPropostasEsteiraEtapa(lstDados);
        setLstPropostasEsteiraCompleta(lstDados);
        if (etapaEsteira === 'TODAS') {
          var etapasTotaisTemp = { ...etapasTotaisIniciais };
          lstDados = lstDados.map(ppc => {
            etapasTotaisTemp[ppc.etapaAtual]++;
            return ppc;
          });
          etapasTotaisTemp['TODAS'] = lstDados.length;
          setEtapasTotais(etapasTotaisTemp);
        }
        setQtdMostrar(10);
        setValorFiltro(false);
      })
      .catch(err => {
        setLstPropostasEsteira(false);
        setMostraErro(err.response);
      })
      .finally(() => {
        setIsPending(false);
        setLoadPipelinePropostas(false);
        // loadingDashFim('PropostasEsteira');
      });
  };

  const trocarAba = newValue => {
    var etapa = lstEtapas[newValue] ? lstEtapas[newValue].etapa : 0;
    mudarAba(newValue);
    setFiltrarEtapa(etapa);
  };

  useEffect(() => {
    if (filtroDashboard && filtroDashboard.filtrar === false) return false;
    setMostrarGerente(filtroDashboard && filtroDashboard.gerente && filtroDashboard.gerente === 'todos');
    setBuscarPropostas(false);
    parentMudarAbaAtiva(1);
  }, [filtroDashboard]);

  useEffect(() => {
    if (buscarPropostas) {
      if (filtrarEtapa === 'TODAS') {
        buscarPropostasEsteiras();
      } else {
        trocarAba(0);
      }
    }
  }, [buscarPropostas]);

  useEffect(() => {
    if (filtrarEtapa === false) return false;
    if (filtrarEtapa === 'TODAS') {
      buscarPropostasEsteiras();
    } else {
      setQtdMostrar(10);
      var tempLstPropostasEsteiraEtapa = [...lstPropostasEsteiraCompleta].filter(
        prop => prop.etapaAtual === filtrarEtapa
      );
      setLstPropostasEsteira(tempLstPropostasEsteiraEtapa);
      setLstPropostasEsteiraEtapa(tempLstPropostasEsteiraEtapa);
      setValorFiltro(false);
    }
  }, [filtrarEtapa]);

  useEffect(() => {
    if (
      dadosUser &&
      (dadosUser.nivel === nivelUsuarioEnum.ADMINISTRADOR ||
        dadosUser.nivel === nivelUsuarioEnum.DIRETOR)
    )
      setShowHistoricoAtivo(true);
  }, []);

  useEffect(() => {
    if (parentAbaAtiva === 0) {
      setBuscarPropostas(true);
    }
    setQtdMostrar(10);
  }, [parentAbaAtiva]);

  useEffectAfterMount(() => {
    if (showHistoricoAtivo) {
      if (filtrarEtapa === 'TODAS') {
        buscarPropostasEsteiras();
      } else {
        trocarAba(0);
      }
    }
  }, [buscarHistoricoAtivo]);

  useEffect(() => {
    if (valorFiltro === false) return false;
    buscarCnpjNome(valorFiltro);
  }, [valorFiltro]);

  const handleChangeBuscaProposta = event => {
    setValorFiltro(event.target.value);
  };

  const buscarCnpjNome = valorBuscar => {
    var tempLstPropostasEsteira = [...lstPropostasEsteiraEtapa];
    if (valorBuscar !== '') {
      tempLstPropostasEsteira = tempLstPropostasEsteira.filter(
        prop =>
          mascararCpfCnpj(prop.cnpjCliente) === valorBuscar ||
          prop.cnpjCliente === valorBuscar ||
          likeStringMatch(prop.nomeCliente, valorBuscar + '%')
      );
    }
    setLstPropostasEsteira(tempLstPropostasEsteira);
  };

  const handleClickLimparFiltro = () => {
    setValorFiltro('');
  };

  const clickParecer = (codppc, cnpjCliente, nomeCliente) => {
    setModalGrid({
      codppc: codppc,
      cnpjCliente: cnpjCliente,
      nomeCliente: nomeCliente,
      modal: 'parecer',
      modalTitle: 'Pareceres'
    });
  };

  const clickCheckpoints = (cnpjCliente, nomeCliente) => {
    setModalGridCheckpoints({
      cnpjCliente: cnpjCliente,
      nomeCliente: nomeCliente,
      modal: 'checkpoints',
      ckeckpointsClientes: false,
      modalTitle: 'Checkpoints Propostas'
    });
  };

  return (
    <>
      <Box sx={isPending ? { position: 'relative' } : {}}>
        {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
        <Tabs
          value={parseInt(abaAtiva)}
          onChange={(event, newValue) => trocarAba(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          className="tabs-etapas-propostas borda-bottom-1 borda-cor-cinza-claro-2 margin-top-25 margin-bottom-35"
        >
          {lstEtapas.map((step, index) => (
            <Tab
              key={step.etapa}
              title={step.titulo}
              icon={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <>
                  <Box className="badge-padrao badge-cor-padrao-cinza badge-selecionavel margin-right-5">
                    {String(etapasTotais[step.etapa] ? etapasTotais[step.etapa] : 0).padStart(2, '0')}
                  </Box>
                  <span className="texto-semi-negrito font-size-080 texto-cor-cinza-escuro texto-hidden-overflow">{` ${step.titulo}`}</span>
                </>
              }
              sx={{ minWidth: 'fit-content', flex: 1, padding: '0 3px' }}
              item={index}
              className="sem-uppercase"
            />
          ))}
        </Tabs>
        <Grid container>
          <Grid xl={3} lg={4} md={4} sm={8} xs={12} item>
            {lstPropostasEsteiraEtapa && lstPropostasEsteiraEtapa.length > 0 && (
              <TextField
                label="Busque CNPJ ou Nome"
                onChange={handleChangeBuscaProposta}
                value={valorFiltro ? valorFiltro : ''}
                variant="standard"
                // eslint-disable-next-line react/jsx-wrap-multilines
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: valorFiltro ? (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickLimparFiltro} edge="end">
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  )
                }}
              />
            )}
          </Grid>
          <Grid xl={9} lg={8} md={8} sm={4} xs={12} item>
            {showHistoricoAtivo && (
              <Box className="texto-direita">
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Switch
                      checked={buscarHistoricoAtivo}
                      onChange={() => setBuscarHistoricoAtivo(!buscarHistoricoAtivo)}
                      name="historicoAtivo"
                    />
                  }
                  title="Mostrar todos os ativos independente de já ter operado, ou indeferido ou ainda em formalização"
                  label="Mostrar Todos os Ativos"
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <AlertErroMain exibeErro={mostraErro} escondeErro={setMostraErro} classes="margin-bottom-10" />
        {/* LISTA */}
        {lstPropostasEsteira !== false ? (
          lstPropostasEsteira.length > 0 ? (
            lstPropostasEsteira.slice(0, qtdMostrar).map(proposta => (
              <Box
                key={proposta.codPPC}
                className="fundo-cor-cinza border-radius-8 pad-topbot-15 pad-right-left-10 margin-bottom-15"
              >
                <Grid container>
                  {dadosUser && dadosUser !== nivelUsuarioEnum.CLIENTE && (
                    <Box className="flex-direction-column margin-top-15">
                      <Box className="circulo-esteira flex-box-items-centralizados margin-bottom-35">
                        <IconButton
                          onClick={() => clickCheckpoints(proposta.cnpjCliente, proposta.nomeCliente)}
                          sx={{ p: '0 2px', color: '#5b5b5b' }}
                          className="badge-padrao-2"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                      <Box className="circulo-esteira flex-box-items-centralizados margin-top-35">
                        <IconButton
                          onClick={() =>
                            clickParecer(proposta.codPPC, proposta.cnpjCliente, proposta.nomeCliente)
                          }
                          sx={{ p: '0 2px', color: '#1976d2' }}
                          className="badge-padrao-2"
                        >
                          <Feed />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                  <Grid
                    xl={mostrarGerente ? 2 : 1}
                    lg={mostrarGerente ? 3 : 2}
                    md={mostrarGerente ? 3 : 2}
                    sm={mostrarGerente ? 3 : 2}
                    xs={mostrarGerente ? 4 : 3}
                    item
                    container
                    className="padding:5px"
                  >
                    {mostrarGerente && (
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={5}
                        xs={5}
                        item
                        className="flex-box-items-centralizados padding:5px"
                      >
                        {criarGerenteAvatarPipeLine({
                          value: proposta.nomeGerente,
                          row: proposta
                        })}
                      </Grid>
                    )}
                    <Grid
                      xl={mostrarGerente ? 8 : 12}
                      lg={mostrarGerente ? 8 : 12}
                      md={mostrarGerente ? 8 : 12}
                      sm={mostrarGerente ? 7 : 12}
                      xs={mostrarGerente ? 7 : 12}
                      item
                      className="flex-box items-centro-verticalmente conteudo-centro texto-esquerda flex-box-wrap"
                    >
                      {criarClienteNomeCnpjPipeLine({
                        value: proposta.nomeCliente,
                        row: { cnpjCliente: proposta.cnpjCliente }
                      })}
                      <Box className="margin-top-5">
                        {lstTiposPropostas[proposta.tipoProposta] && (
                          <Box
                            className="badge-padrao-proposta-cliente-checkpoint fundo-cor-principal texto-cor-branca margin-right-5"
                            title={
                              proposta.dthrInsertFmt
                                ? `Iniciada em ${proposta.dthrInsertFmt.replace(' ', ' ás ')}`
                                : ''
                            }
                          >
                            {lstTiposPropostas[proposta.tipoProposta]}
                          </Box>
                        )}
                        {/*<IconButton
                          onClick={() =>
                            clickParecer(proposta.codPPC, proposta.cnpjCliente, proposta.nomeCliente)
                          }
                          sx={{ p: '0 2px', color: '#1976d2' }}
                        >
                          <Feed />
                        </IconButton>*/}
                        <Box className="flex-direction-column flex-box items-centro-verticalmente" />
                        {proposta.etapaAtual !== 'CONCLUIDO' && proposta.slaExpiracao && (
                          <Box className="badge-padrao-2 fundo-cor-cinza-claro-3 borda-cor-cinza-claro-3 texto-cor-cinza-escuro">
                            {proposta.slaExpiracao >= 0
                              ? `Expiração: ${proposta.slaExpiracao} dias`
                              : `Expirado á ${Math.abs(proposta.slaExpiracao)} dias`}
                          </Box>
                        )}
                        <Box className="flex-direction-column items-centro-verticalmente badge-padrao-proposta-cliente-checkpoint fundo-cor-cinza-claro-3 borda-cor-cinza-claro-3 texto-cor-cinza-escuro">
                          {`Total: ${proposta.totalPeriodos ? proposta.totalPeriodos : 0} dias`}
                        </Box>
                        {proposta.checkpoint_descricao && (
                          <Box
                            title={retornaInformacoesNasPillsDeCheckpoints(
                              proposta.data_insercao,
                              proposta.checkpoint_descricao,
                              proposta.nome_usuario_insercao,
                              proposta.checkpoint_observacao
                            )}
                            className="flex-direction-column items-centro-verticalmente badge-padrao-proposta-cliente-checkpoint fundo-cor-complementar texto-cor-branca"
                          >
                            {`${proposta.checkpoint_descricao ? proposta.checkpoint_descricao : ''}`}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    xl={mostrarGerente ? 9 : 10}
                    lg={mostrarGerente ? 8 : 9}
                    md={mostrarGerente ? 8 : 9}
                    sm={mostrarGerente ? 8 : 9}
                    xs={mostrarGerente ? 7 : 8}
                    item
                  >
                    <Box className="flex-box-items-centralizados items-centralizados-esquerda">
                      <EsteiraProposta
                        proposta={proposta}
                        setPropostaCliente={setPropostaCliente}
                        setMostraErro={setMostraErro}
                        modalDados={modalDados}
                        setModalDados={setModalDados}
                        dadosUser={dadosUser}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))
          ) : (
            <div className="error">
              <h1>Nenhuma proposta encontrada</h1>
            </div>
          )
        ) : (
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
          </div>
        )}
        {lstPropostasEsteira.length - qtdMostrar > 0 && (
          <Grid container>
            <Grid item xl={5} lg={5} md={4} sm={2} xs={0} />
            <Grid item xl={2} lg={2} md={4} sm={5} xs={12}>
              <ButtonMain
                type="button"
                tipoBotao="transparente-com-bordas-principal"
                onClick={() => setQtdMostrar(qtdMostrar + 10)}
                disabled={isPending}
              >
                <Box>
                  <Box className="display-inline-block">Carregar Mais</Box>
                  <Box className="posicao-absoluta badge-padrao fundo-cor-principal texto-cor-branca display-inline-block">
                    {String(lstPropostasEsteira.length - qtdMostrar).padStart(2, '0')}
                  </Box>
                </Box>
              </ButtonMain>
            </Grid>
          </Grid>
        )}
      </Box>

      <ModalEsteiraProposta modalDados={modalDados} setModalDados={setModalDados} />
      <ModalGridDashboardPareceres modalGrid={modalGrid} setModalGrid={setModalGrid} />
      {modalGridCheckpoints && (
        <ModalGridDashboardCheckpoints
          modalGridCheckpoints={modalGridCheckpoints}
          setModalGridCheckpoints={setModalGridCheckpoints}
          lstPropostasEsteira={lstPropostasEsteira}
          setLstPropostasEsteira={setLstPropostasEsteira}
          lstPropostasEsteiraCompleta={lstPropostasEsteiraCompleta}
          setLstPropostasEsteiraCompleta={setLstPropostasEsteiraCompleta}
        />
      )}

      {propostaCliente && (
        <Redirect
          to={{
            pathname: '/authentication/change-client',
            state: { cliente: propostaCliente, location }
          }}
        />
      )}
    </>
  );
};

export default PropostasEsteira;
