import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Form, Formik, Field } from 'formik';

import { mascararCpfCnpj } from '../../../shared';
import { getListarPipeComercial, postSalvarPipeComercial } from '../../../api/dashboard';
import LoaderMain from '../../Onboarding/shared/LoaderMain';

import ButtonMain from '../../Onboarding/shared/ButtonMain';
import InputMain from '../../Onboarding/shared/InputMain/InputMain';
import { unmaskValue } from '../../../utils/format';

const ModalGridDashboardPipeComercial = ({
  modalGridPipeComercial,
  setModalGridPipeComercial,
  setAlerta,
  setListaClientes
}) => {
  const [isPending, setIsPending] = useState(false);
  const [dadosPipeComercial, setDadosPipeComercial] = useState([]);

  useEffect(() => {
    if (modalGridPipeComercial && modalGridPipeComercial.cnpjCliente) {
      setIsPending(true);
      setDadosPipeComercial([]);
      getListarPipeComercial(modalGridPipeComercial.params.id)
        .then(res => {
          const dados = res.data;
          setDadosPipeComercial(dados);
        })
        .catch(() => {
          setAlerta({
            mensagem: 'Houve um erro ao salvar o pipe comercial. Favor contatar o suporte.',
            tipo: 'error'
          });
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [modalGridPipeComercial]);

  const salvarLimiteComercial = (form, params) => {
    postSalvarPipeComercial(params)
      .then(res => {
        if (res && res.data) {
          let dados = res.data;
          setAlerta({
            mensagem: dados.mensagem,
            tipo: dados.error ? 'warning' : 'success'
          });
          setModalGridPipeComercial(false);
        }
      })
      .catch(error => {
        let mensagemError = error.response.data['mensagem']
          ? error.response.data['mensagem']
          : 'Houve um erro ao salvar o pipe comercial. Favor contatar o suporte.';
        if (error.response && error.response.data && error.response.data['error'] === false) {
          setIsPending(false);
          form.setSubmitting(false);
        } else {
          setModalGridPipeComercial(false);
        }
        setAlerta({
          mensagem: mensagemError,
          tipo: 'error'
        });
      });
  };

  const cadastrarPipeComercial = (values, form) => {
    if (modalGridPipeComercial && modalGridPipeComercial.cnpjCliente) {
      let params = {
        codCliente: modalGridPipeComercial.params.id,
        observacao: values.observacao || '',
        valorPipeComercial: unmaskValue(values.valorPipeComercial) || 0
      };

      setIsPending(true);
      salvarLimiteComercial(form, params);
      setListaClientes(prevState =>
        prevState.map(cliente =>
          cliente.cnpjCliente === modalGridPipeComercial.cnpjCliente
            ? {
                ...cliente,
                valorPipeComercial: unmaskValue(values.valorPipeComercial)
              }
            : cliente
        )
      );
    }
  };

  return (
    <Dialog
      open={modalGridPipeComercial !== false}
      fullWidth={true}
      maxWidth="md"
      keepMounted
      onClose={() => setModalGridPipeComercial(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="texto-cor-principal texto-negrito">
        <Box className="items-centralizados-esquerda borda-bottom-2-gray pad-topbot-10">
          Pipe Comercial
          <IconButton
            onClick={() => setModalGridPipeComercial(false)}
            className="float-right margin-neg-top-5"
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {modalGridPipeComercial ? (
          <Box className="box-dashboard">
            {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
            <Box className="pad-5 sub-titulo border-radius-8 margin-top-10">
              <Typography
                variant="subtitle2"
                className="font-size-1-1-rem texto-cor-cinza-escuro font-bold pl-2"
              >
                {modalGridPipeComercial.nomeCliente.toUpperCase()}
                {' - ' + mascararCpfCnpj(modalGridPipeComercial.cnpjCliente)}
              </Typography>
            </Box>
            <Box className="pad-5 sub-titulo-azul border-radius-8 margin-top-20">
              <Typography variant="subtitle2" className="font-size-1-1-rem texto-cor-cinza-escuro pl-2">
                Liquidação estimada para este mês: R$&nbsp;
                <span className="font-bold">
                  {dadosPipeComercial &&
                    dadosPipeComercial.previsaoLiquidez &&
                    parseFloat(dadosPipeComercial.previsaoLiquidez).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                </span>
              </Typography>
            </Box>
            <Grid
              className="margin-top-25 borda-bottom-2-main"
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="margin-top-20">
              <Formik
                enableReinitialize
                initialValues={dadosPipeComercial}
                onSubmit={(values, form) => cadastrarPipeComercial(values, form)}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <Grid container>
                      <>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <Field
                            type="text"
                            name="valorPipeComercial"
                            titulo="Valor Pipe comercial"
                            id="valorPipeComercial"
                            size="small"
                            className="select-dashBoard"
                            component={InputMain}
                            tipoMascara="number-money"
                            autoComplete="off"
                            disabled={
                              isPending ||
                              isSubmitting ||
                              (dadosPipeComercial && dadosPipeComercial.travaPipe)
                            }
                          />
                          {dadosPipeComercial && dadosPipeComercial.metaCadastrada === false && (
                            <Typography variant="subtitle2" className="informativo-sub-titulo">
                              Valor sugerido com base no cálculo do limite comercial + liquidação
                              estimada.
                            </Typography>
                          )}
                        </Grid>
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Field
                              titulo={
                                // eslint-disable-next-line prettier/prettier, react/jsx-wrap-multilines
                                <>
                                  Observação&nbsp;
                                  <Typography component="span" variant="body2" color="textSecondary">
                                    (opcional)
                                  </Typography>
                                </>
                              }
                              name="observacao"
                              placeholder="Observação"
                              id="observacao"
                              fullWidth
                              uppercase={false}
                              component={InputMain}
                              autoComplete="off"
                              multiline
                              rows={4}
                              inputProps={{ maxLength: 10000 }}
                              disabled={
                                isPending ||
                                isSubmitting ||
                                (dadosPipeComercial && dadosPipeComercial.travaPipe)
                              }
                            />
                          </Grid>
                        </Grid>
                      </>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        direction="row"
                        sx={{
                          justifyContent: 'flex-end',
                          alignItems: 'center'
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap={1.5}
                          sx={{ width: 'auto', mt: 2 }}
                        >
                          <ButtonMain
                            tipoBotao="transparente"
                            onClick={() => setModalGridPipeComercial(false)}
                          >
                            Cancelar
                          </ButtonMain>
                          <ButtonMain
                            tipoBotao="azul-escuro"
                            onClick={submitForm}
                            disabled={
                              isPending ||
                              isSubmitting ||
                              (dadosPipeComercial && dadosPipeComercial.travaPipe)
                            }
                          >
                            Salvar
                          </ButtonMain>
                        </Box>
                      </Grid>
                      {isSubmitting && <LinearProgress />}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Box>
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalGridDashboardPipeComercial;
